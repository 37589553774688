@font-face{
  font-family: 'Cream Peach';
  src: url('../fonts/Cream_Peach.ttf');
}
@font-face{
  font-family: 'Myriad Pro Semibold';
  src: url('../fonts/Myriad_Pro_Semibold.ttf');
}
@font-face{
  font-family: 'Myriad12';
  src: url('../fonts/Myriad12.otf');
}
@font-face{
  font-family: 'MyriadPro Bold';
  src: url('../fonts/MyriadPro_Bold.otf');
}
@font-face{
  font-family: 'MyriadPro Regular';
  src: url('../fonts/MyriadPro_regular.otf');
}
@font-face{
  font-family: 'CenturyGothic';
  src: url('../fonts/CenturyGothic.ttf');
}
.cream{
  font-family: 'Cream Peach';
}
.myriad_semi{
  font-family: 'Myriad Pro Semibold';
}
.myriad12{
  font-family: 'Myriad12';
}
.myriad_bold{
  font-family: 'MyriadPro Bold';
}
.myriad_regular{
  font-family: 'MyriadPro Regular';
}
.Century{
  font-family: 'CenturyGothic';
}
.color_gris{
  color: #686f7b;
}
.color_negro{
  color: #000000;
}
.color_amarillo{
  color: #fccd35;
}
.color_blanco{
  color:#ffffff;
}
.back_gris{
  background-color: #686f7b;
}
.back_negro{
  background-color: #000000;
}
.back_amarillo{
  background-color: #fccd35;
}
.justificar{
  text-align: justify;
}
@keyframes shrink {
  0% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}
.contenedor_banner_fondo{
  position: relative!important;
}

.banner_fondo{
  position: inherit!important;
}
.img_banner_fondo{
  width: 100%;
}
.contenedor_frente_fondo{
  position: absolute!important;
  height: 100% !important;
  width: 100%;
}
.frente_fondo {
  margin-top: 70%;
  height: 100% !important;
}